import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolets coriacis en forma de copinya de 2-10 x 1-5 cm que surten en grups de nombrosos individus adherits a l’escorça dels arbres lateralment. La cara superior presenta una zonació concèntrica amb bandes de distints colors que van de marró més o menys pàl·lid a ocraci i fins i tot negrós. Les espores són de color crema en massa, cilíndriques i allargades, de 5-6 x 1,5-2,2 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      